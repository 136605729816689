import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h1>{`Nihao, I'm Hamza.`}</h1>
    <p>{`I'm a Software Engineer with a passion for Graphic Design and Photography.
Which is not entirely a waste, but rather a pretty good reason why I'm good at creating incredible web experiences. Care to know more? `}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      