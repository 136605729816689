import React from "react"
import { Parallax } from "react-spring/renderprops-addons.cjs"
import Layout from "../components/layout"
import Hero from "../components/hero"
import Projects from "../components/projects"
import About from "../components/about"
import Contact from "../components/contact"
import Header from "../components/header"
import Divider from "../elements/divider"
import { UpDownWide } from "../styles/animations"
import AnimatedThreeJsCanvasContainer from "../components/AnimatedThreeJsCanvasContainer";
import Logo from "../components/Logo"

const ikteru = ({ offset, factor = 1 }: { offset: number; factor?: number }) => (
  <Layout>
    <Parallax pages={1}>
      <Divider speed={0.2} offset={offset} factor={factor}>
        <UpDownWide>
          {/* <AnimatedThreeJsCanvasContainer></AnimatedThreeJsCanvasContainer> */}
        </UpDownWide>
      </Divider>
      <Hero offset={0} factor={1} />

    </Parallax>
  </Layout>
)

export default ikteru
