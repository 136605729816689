/** @jsx jsx */
import { jsx } from "theme-ui"
import Divider from "../elements/divider"
import Inner from "../elements/inner"
import Content from "../elements/content"
import SVG from "./svg"
import { UpDown, UpDownWide } from "../styles/animations"
import AnimatedThreeJsCanvasContainer from "../components/AnimatedThreeJsCanvasContainer";

// @ts-ignore
import Intro from "../sections/intro"
import Logo from "./Logo"

const Hero = ({ offset, factor = 1 }: { offset: number; factor?: number }) => (
  <div>
    <Divider speed={0.2} offset={offset} factor={factor}>
      <UpDownWide sx={{ position: "relative", top: "0", right: "0"}}>
        {/* <AnimatedThreeJsCanvasContainer></AnimatedThreeJsCanvasContainer> */}
        <Logo />
      </UpDownWide>
    </Divider>
    <Content sx={{ variant: `texts.bigger` }} speed={0.4} offset={offset} factor={factor}>
      <Inner>
        <Intro />
      </Inner>
    </Content>
  </div>
)

export default Hero
